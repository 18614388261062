'use client';

export default function GlobalError(/* { error, reset, }: { error: Error & { digest?: string }; reset: () => void; } */): JSX.Element {
  return (
    <div className="error">
      <h2>Something went wrong!</h2>
      <a href="/">Go back to Home page</a>
    </div>
  );
}
